import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue"; // import { Swiper as swiper } from "swiper";
// Import Swiper styles

import "swiper/swiper.min.css";
export default {
  components: {
    Swiper: Swiper,
    SwiperSlide: SwiperSlide
  },
  props: {
    data: {
      type: Object,
      default: function _default() {}
    },
    parseName: String,
    current: Number
  },
  data: function data() {
    return {
      // current: 0,
      xqCurrent: 0,
      startNum: 0,
      mySwiper: null
    };
  },
  watch: {
    current: {
      immediate: true,
      handler: function handler(c) {
        if (c > 4) {
          var v = c - 4;
          var len = this.a.length;
          this.startNum = v > len - 5 ? len - 5 : v;
        } else {
          this.startNum = 0;
        }

        this.xqCurrent = 0;
      }
    },
    upData: {
      immediate: true,
      handler: function handler() {
        this.upMeta();
      }
    }
  },
  computed: {
    upData: function upData() {
      var current = this.current,
          parseName = this.parseName;
      return {
        current: current,
        parseName: parseName
      };
    },
    detail: function detail() {
      return this.data.data[this.current].detail || [];
    },
    x: function x() {
      // console.log(-(150 * this.startNum) + "px");
      return -(1.5 * this.startNum) + "rem";
    },
    a: function a() {
      return this.data.data;
    },
    atData: function atData() {
      return this.data.data[this.current];
    },
    b: function b() {
      return this.data.data[this.current].b || [];
    },
    cTitle: function cTitle() {
      return this.data.data[this.current].cTitle;
    },
    c: function c() {
      //应用
      return this.data.data[this.current].c || [];
    },
    dTitle: function dTitle() {
      return this.data.data[this.current].dTitle;
    },
    d: function d() {
      //参数
      return this.data.data[this.current].d || [];
    },
    d1: function d1() {
      //特色
      return this.data.data[this.current].d1 || [];
    },
    eTitle: function eTitle() {
      return this.data.data[this.current].eTitle;
    },
    e: function e() {
      //展示
      return this.data.data[this.current].e || [];
    },
    f: function f() {
      //无数据是显示
      return this.data.data[this.current].f;
    }
  },
  methods: {
    setSwiperInstance: function setSwiperInstance(s) {
      this.mySwiper = s;
    },
    format: function format(str) {
      var reg = /<\/?.+?\/?>/g;
      return str.replace(reg, "");
    },
    setMeta: function setMeta(name, value) {
      var dom = document.querySelector("meta[name=\"".concat(name, "\"]"));
      dom && dom.setAttribute("content", value);
    },
    upMeta: function upMeta() {
      var title = this.atData.name,
          parseName = this.parseName,
          setMeta = this.setMeta,
          b = this.b;
      var description;

      if (Array.isArray(b) && b[0]) {
        description = b[0].c;
      }

      document.title = title;
      setMeta("keywords", parseName + title);
      setMeta("description", description);
      setMeta("title", title);
    },
    addImage: function addImage(sItem) {
      var c = sItem.c;

      if (sItem.images) {
        sItem.images.forEach(function (image) {
          c += "<img src='".concat(image, "' style=\"width: 100%\"/>");
        }); // c += `<img src='${sItem.images[0]}' style="width: 100%"/>`;
        // c += 123;
      }

      return c;
    },
    labelWidth: function labelWidth(item, sItem) {
      if (sItem.labelWidth !== undefined && sItem.labelWidth !== null) {
        return "width:".concat(sItem.labelWidth, ";");
      } else if (item.labelWidth) {
        return "width:".concat(item.labelWidth, ";");
      } else {
        return "";
      }
    },
    toArray: function toArray(d) {
      if (Array.isArray(d)) {
        return d;
      } else {
        return [d];
      }
    },
    tapXpCurrent: function tapXpCurrent(index) {
      // if (index === 0) {
      //   $(".my-swiper").addClass("my-swiper-transition");
      //   setTimeout(() => {
      //     $(".my-swiper").removeClass("my-swiper-transition");
      //   }, 1000);
      // }
      // new swiper(".my-swiper").slideTo(index, 1000);
      this.mySwiper.slideTo(index);
      this.xqCurrent = index;
    },
    slideChange: function slideChange($event) {
      this.xqCurrent = $event.activeIndex;
    }
  } // mounted() {
  //   console.log(this.data.data);
  // },

};